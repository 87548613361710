<script setup lang="ts">
import { type NavigationNodeMain, useNavigationStore } from '~/store/navigation'
import { storeToRefs } from 'pinia'

const visible = useMenuOverlay()

const navigationStore = storeToRefs(useNavigationStore())
const menuItems = navigationStore.navigation

const showSub = ref(false)
const subItems = ref<NavigationNodeMain[]>([])
function toggleSub(i: number) {
	if (menuItems.value.main[i].children.length > 0) {
		subItems.value = menuItems.value.main[i].children
		showSub.value = true
	} else {
		showSub.value = false
	}
}
function disableOrToggle(i: number, hasChildren: boolean) {
	if (typeof window !== 'undefined') {
		if (window.innerWidth > 768) {
			visible.value = false
		} else {
			if (hasChildren) {
			} else {
				visible.value = false
			}
		}
	} else {
		showSub.value = false
	}
}
function disableSub() {
	showSub.value = false
}
</script>

<template>
	<transition name="fade">
		<nav
			v-show="visible"
			id="main-nav"
			class="menu-panel bg-white fixed z-[101] top-0 left-0 w-full h-full flex"
		>
			<button
				class="menu-panel__close transition duration-400 absolute right-4 top-4 z-40"
				@click="visible = false"
			>
				<svg
					class="w-8 h-8 md:w-12 md:h-12 hover:rotate-90 transition duration-300 hover:scale-105"
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect width="48" height="48" rx="24" fill="#00E63C" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M22.6667 23.8203L18 28.487L19.3334 29.8203L24 25.1537L28.6667 29.8203L30 28.487L25.3334 23.8203L30 19.1536L28.6667 17.8203L24 22.487L19.3333 17.8203L18 19.1537L22.6667 23.8203Z"
						fill="black"
					/>
				</svg>
			</button>
			<div
				class="menu-panel__items transition duration-800 ease-out relative z-30 w-full md:w-1/2"
			>
				<div
					class="menu-panel__main relative z-10 delay-0 h-full transition-[width] duration-200 ease-out bg-black py-[10%] md:py-[5%] px-[15vw] md:px-[5vw] flex flex-col justify-between pointer-events-auto"
					:class="{
						'w-[130%] blur-[10px] md:blur-none translate-x-[-33.3%] md:translate-x-0 md:w-2/3':
							showSub,
						'w-full': !showSub,
					}"
				>
					<div
						class="translate-x-[-300%] transition delay-200 duration-300 absolute w-full h-full top-0 left-0 md:hidden"
						:class="{
							'!translate-x-0': showSub,
						}"
						@click="disableSub"
					></div>
					<ul
						class="menu-main text-[30px] md:text-[40px] leading-none tracking-[-1px] text-white font-semibold grow flex flex-col justify-center gap-[30px] md:gap-[5vh]"
					>
						<li v-for="(item, index) in menuItems.main">
							<NuxtLink
								@click="
									disableOrToggle(
										index,
										item.children.length > 0 ? true : false,
									)
								"
								@mouseover="toggleSub(index)"
								class="inline-block hover:translate-x-1 transition duration-400"
								:class="{
									external: item.typeLabel === 'Custom URL',
									hasSub: item.children.length,
								}"
								:to="
									item.nodeUri.startsWith('http')
										? item.nodeUri
										: `/${item.nodeUri}`
								"
								:target="item.newWindow && '_blank'"
								>{{ item.title }}</NuxtLink
							>
						</li>
					</ul>
					<SocialMenu :items="menuItems.social"></SocialMenu>
				</div>

				<Transition name="show-sub-menu">
					<div
						v-if="showSub"
						class="menu-panel__sub absolute top-0 z-[100] md:z-0 h-full left-[33.33%] md:left-[66.666%] w-2/3 bg-nemo-yellow px-[10vw] md:px-[5vw] flex flex-col justify-center"
					>
						<ul
							class="menu-sub text-[20px] md:text-[24px] leading-[1.2] tracking-[-1px] text-black font-semibold grow flex flex-col justify-center gap-[21px] md:gap-[3vh]"
						>
							<li v-for="subItem in subItems">
								<NuxtLink
									@click="visible = false"
									class="inline-block hover:translate-x-1 transition duration-400"
									:to="
										subItem.nodeUri.startsWith('http')
											? subItem.nodeUri
											: `/${subItem.nodeUri}`
									"
									:target="subItem.newWindow && '_blank'"
									>{{ subItem.title }}</NuxtLink
								>
							</li>
						</ul>
					</div>
				</Transition>
			</div>

			<div
				class="menu-panel__info transition-all duration-800 linear relative z-0 hidden md:block md:w-1/2"
			>
				<div
					class="menu-panel__info__overlay absolute z-10 right-0 top-0 w-full h-full backdrop-blur-[0px] transition-all duration-0 linear delay-0 pointer-events-none"
					:class="{
						'!backdrop-blur-lg': showSub,
					}"
				></div>
				<Kamagurka
					:pagination="true"
					@clicked-publication="visible = false"
				></Kamagurka>
			</div>
		</nav>
	</transition>
</template>

<style scoped>
.fade-enter-active {
	transition: transform 1s cubic-bezier(0.01, 0.99, 0.01, 0.99);
}
.fade-leave-active {
	transition: transform 0.25s cubic-bezier(0.28, 0.04, 0.75, 0.51);
}

@media (max-width: 768px) {
	.fade-enter-from,
	.fade-leave-to {
		@apply translate-x-[-100%];
	}
	.fade-enter-to,
	.fade-leave-from {
		@apply translate-x-0;
	}
}
@media (min-width: 768px) {
	.fade-enter-from,
	.fade-leave-to {
		@apply translate-y-[-100%];
	}
	.fade-enter-to,
	.fade-leave-from {
		@apply translate-y-0;
	}
}

.external {
	@apply after:w-6 after:h-6 after:inline-block  after:content-[''] after:ml-1.5 after:bg-menu-arrow-external hover:after:translate-x-1 hover:after:-translate-y-1 after:transition after:ease-out after:duration-200;
}
.hasSub {
	@apply after:w-6 after:h-6 after:inline-block  after:content-[''] after:ml-1.5 after:bg-menu-arrow-submenu hover:after:translate-x-2 after:transition after:ease-out after:duration-200;
}

.active {
	@apply relative overflow-hidden after:content-[''] after:block after:w-5 after:h-5 after:bg-nemo-yellow after:absolute after:right-0 after:top-[50%] after:translate-y-[-50%] after:translate-x-[150%] after:rotate-45 after:animate-menu-arrow-in;
}

.show-sub-menu-enter-active,
.show-sub-menu-leave-active {
	@apply transition-all duration-200 ease-out;
}
@media (max-width: 768px) {
	.show-sub-menu-enter-from,
	.show-sub-menu-leave-to {
		@apply opacity-0 translate-x-[100%];
	}
	.show-sub-menu-enter-to,
	.show-sub-menu-leave-from {
		@apply opacity-100 translate-x-[0%];
	}
}

@media (min-width: 768px) {
	.show-sub-menu-enter-from,
	.show-sub-menu-leave-to {
		@apply opacity-0 translate-x-[-50%];
	}
	.show-sub-menu-enter-to,
	.show-sub-menu-leave-from {
		@apply opacity-100 translate-x-[0%];
	}
}
</style>
